body {
  font-family: red-hat-display, sans-serif;

  a:-webkit-any-link {
    text-decoration: none;
  }
}

.bold-text {
  font-weight: 600;
}

.page-second-opinion {
  &-header {
    min-height: 80px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;

    sup {
      font-size: 10px;
      font-weight: 500;
    }

    .logo-curia {
      &-img {
        background-image: url(./images/curia-logo.svg);
        width: 180px;
        height: 32px;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .header-text {
      &-in {
        font-size: 28px;
        font-weight: 600;

        sup {
          font-size: 50%;
          vertical-align: super;
          font-weight: 700;
        }
      }
    }

    .header-buttons {
      .header-button-btn {
        .btn-book-now {
          background-color: #a94476;
          border-radius: 28px 28px 28px 28px;
          padding: 13px 35px 13px 35px;
          color: #fff;
          font-size: 18px;
        }
      }
    }
  }

  .for-mobile {
    display: none;
  }

  &-container {
    .slider-show {
      position: relative;

      .carousel-content {
        position: absolute;
        z-index: 99;
        bottom: calc(100% - 92%);
        width: 100%;
        text-align: center;

        .slide-main-text {
          font-size: 45px;
          color: #fff;
          font-weight: 900;
          text-shadow: 0px 4px 2px #2e02174d;

          &.slide-main-text-bottom {
            line-height: 25px;
            padding-bottom: 10px;
          }
        }

        .slide-sub-text-top {
          color: #fff;
          padding: 15px 15px 0px 15px;
          text-shadow: 0px 4px 2px #2e02174d;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
        }

        .slide-sub-text {
          color: #fff;
          // padding: 10px 15px 0 15px;
          text-shadow: 0px 4px 2px #2e02174d;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          padding: 10px 15px 0 15px;
        }

        .slide-buttons {
          padding-top: 20px;

          .btn-book-now {
            background-color: #fff;
            border-radius: 28px 28px 28px 28px;
            padding: 13px 35px 13px 35px;
            color: #a94476;
            font-size: 18px;
            display: inline-flex;
          }
        }
      }

      .carousel-slide {
        position: relative;

        &.slide-one {
          .slide-img {
            background-image: url(./images/slide-one.png);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.slide-two {
          .slide-img {
            background-image: url(./images/slide-two.png);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.slide-three {
          .slide-img {
            background-image: url(./images/slide-three.png);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        .slide-img {
          width: 100%;
          height: 768px;

          &::before {
            content: '';
            background-image: url(./images/bg-slide.svg);
            width: 100%;
            height: 768px;
            background-position: bottom;
            background-repeat: repeat-x;
            background-size: auto;
            position: absolute;
            bottom: 0;
          }
        }
      }
    }

    .listen-ytube-page {
      padding-top: 40px;

      .listen-ytube-page-emb-code {
        display: flex;
        justify-content: center;
        padding: 50px 0 20px 0;

        // >div {
        //   min-width: 70%;
        //   min-height: 496px;
        // }

        .react-player__play-icon {
          display: none;
        }
      }

      .listen-ytube-page-title {
        font-size: 30px;
        text-align: center;
        line-height: 38px;
        font-weight: 600;

        .text-small {
          font-size: 24px;
        }
      }

      .book-btn {
        display: inline-flex;
        justify-content: center;
        width: 100%;

        .btn-book-now {
          background-color: #a94476;
          border-radius: 28px 28px 28px 28px;
          padding: 13px 35px 13px 35px;
          color: #fff;
          font-size: 18px;
        }
      }
    }

    .how-does-it-work-page {
      padding: 60px 50px;

      &-title {
        font-size: 40px;
        line-height: 53px;
        text-align: center;
        font-weight: 600;
        padding-bottom: 40px;
        position: relative;

        &::after {
          content: '';
          width: 100px;
          height: 8px;
          background-color: #454343;
          display: flex;
          justify-content: center;
          position: absolute;
          left: 47%;
          bottom: 25px;
        }
      }

      &-layout {
        display: flex;
        align-items: stretch;

        &-left {
          max-width: 69%;
          min-width: 69%;
          padding-right: 15px;
          background: #54738e0f;
          border-right: 30px solid #ffffff;
          display: flex;
          align-items: center;

          .easy-steps {
            // background: #54738e0f;
            mix-blend-mode: normal;
            border-radius: 6px;
            padding: 40px 30px;

            &-title-big {
              color: #a94476;
              font-size: 55px;
              font-weight: 600;
              padding-bottom: 12px;
            }

            &-title-small {
              color: #a94476;
              font-size: 24px;
              font-weight: 600;
            }

            &-show {
              display: flex;
              padding-top: 40px;
              padding-right: 30px;

              &-step {
                min-width: 33%;
                max-width: 33%;
                padding-right: 20px;
                text-align: center;

                &-img {
                  &.step-img-one {
                    background-image: url(./images/step-upload.svg);
                    width: 138px;
                    height: 88px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin: 0 auto;
                  }

                  &.step-img-two {
                    background-image: url(./images/step-pay.svg);
                    width: 138px;
                    height: 88px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin: 0 auto;
                  }

                  &.step-img-three {
                    background-image: url(./images/step-recived.svg);
                    width: 138px;
                    height: 88px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin: 0 auto;
                  }
                }

                &-text {
                  font-size: 16px;
                  line-height: 21px;
                  font-weight: 600;
                  padding-top: 20px;
                }

                .doc-info-text {
                  font-style: italic;
                  font-size: 11px;
                  line-height: 18px;
                  color: #a94476;
                  font-weight: 600;
                }

                .fees-text {
                  color: #A94476;
                  font-weight: 700;
                  padding-top: 12px;
                }
              }
            }
          }

          .easy-steps-show-below {
            border-top: 1px solid #dfdfdf;
            padding-top: 20px;
            margin-top: 20px;

            .easy-steps-show {
              padding-top: 10px;

              &-step {
                &-text {
                  font-size: 18px;
                }
              }

              .doc-info-text {
                color: #000000;
                font-size: 14px;
                padding-top: 15px;
                font-weight: 400;
                font-style: normal;
              }
            }
          }
        }

        &-right {
          max-width: 26%;
          min-width: 26%;
          padding-left: 20px;
          padding-right: 20px;
          background: #faf4f7;
          border-radius: 10px;
          background-image: url(./images/upload-f-bg.svg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: top;
          padding-bottom: 22px;
          position: relative;
          display: flex;
          align-items: center;

          .overlay-sec {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffffd1;
            color: #000;
            font-size: 25px;
            z-index: 99;
            border: 2px solid #b6437012;

            &::after {
              background-image: url(./images/curia-logo.svg);
              width: 180px;
              height: 32px;
              background-repeat: no-repeat;
              background-size: contain;
              content: "";
            }
          }

          .thankyou-page {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            color: #000;
            font-size: 25px;
            z-index: 99;
            border: 2px dashed #b6437012;
            flex-wrap: wrap;
            background-image: url(./images/upload-f-bg.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;

            &-content {
              text-align: center;
              padding: 20px 30px;

              &-text {
                font-weight: 700;
                font-size: 32px;
                padding-bottom: 10px;
                color: #a94476;
              }

              &-text-small {
                font-weight: 700;
                font-size: 24px;
              }

              .thankyou-page-img {
                background-image: url(./images/thankyou.png);
                width: 100%;
                height: 230px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                margin-bottom: 20px;
              }
            }

            .thankyou-page-done {
              padding-top: 10px;

              .ant-btn {
                &.btn-thankyou-page-done {
                  background-color: #a94476;
                  background-image: linear-gradient(to right, #b44171, #87498d);
                  border-radius: 8px;
                  height: 42px;
                  color: #fff;
                  font-weight: 700;
                  font-size: 14px;
                  width: 90px;

                  &:hover {
                    box-shadow: none;
                    border-color: transparent;
                  }
                }
              }
            }
          }

          .upload-form-sec {
            #recaptcha-container {
              padding-top: 15px;
              display: flex;
              justify-content: center;
            }

            .title-sec {
              .form-sec-title {
                font-size: 22px;
                line-height: 28px;
                font-weight: 700;
                text-align: center;
                padding: 20px 0;
              }

              .form-sec-subtitle {
                font-size: 18px;
                line-height: 24px;
                color: #A94476;
                font-style: italic;
                font-weight: 700;
                padding-bottom: 20px;
                text-align: center;
              }
            }

            .form-wrap {
              width: 100%;
              border-bottom: 1px solid #dfdfdf;
              padding-bottom: 15px;
              margin-bottom: 15px;

              .fields-info-text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 0;
                color: #A74970;
                font-size: 12px;
                padding-top: 5px;
                font-weight: 700;
                font-style: italic;
              }

              .form-input-itm {
                padding-bottom: 10px;

                &.question {
                  textarea {
                    height: 55px;
                    background: #FFFFFF;
                    border: 2px solid #d9d7d7;
                    padding-left: 10px;
                    border-radius: 5px;
                    padding-top: 5px;

                    &::placeholder {
                      color: #000000;
                      opacity: 0.5;
                    }
                  }

                  .question-title {
                    font-size: 14px;
                    line-height: 28px;
                    font-weight: 700;
                    padding-bottom: 8px;
                  }
                }

                &.mobilenumber {
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;

                  input {
                    &.country-code {
                      max-width: 11%;
                    }

                    &.mobile-no {
                      max-width: 77%;
                    }

                    &.question {
                      max-width: 45%;
                    }
                  }
                }

                input {
                  height: 45px;
                  background: #FFFFFF;
                  border: 2px solid #d9d7d7;
                  padding-left: 10px;
                  width: calc(100% - 15px);
                  border-radius: 5px;
                }
              }
            }

            .upload-wrap {
              .ant-upload-select {
                display: inline-block;
                background: #FFFFFF;
                border: 1px solid #525151;
                border-radius: 5px;
                width: 100%;
              }

              .upload-title {
                font-size: 14px;
                line-height: 28px;
                font-weight: 700;
                padding-bottom: 8px;
              }

              .upload-input-text {
                padding: 0 10px;
                line-height: normal;
              }

              .upload-input-wrap {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 48px;

                .ant-btn {
                  border-color: #d9d9d9;
                  box-shadow: 0 2px 0 #00000005;
                  background: #DADADA;
                  border-radius: 0px 4px 4px 0px;
                  height: 48px;
                  color: #000;
                  font-weight: 500;
                  font-size: 14px;
                }
              }

              .consent-wrap {
                font-weight: 500;
                color: #525151;
                padding-bottom: 15px;

                .consent-text {
                  a {
                    padding: 0 6px;
                    color: #A74970;
                  }
                }

                .consent-itm {
                  display: flex;
                  align-items: center;
                  padding-bottom: 10px;

                  input {
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                    margin-top: 5px;
                  }
                }
              }

              .upload-form-buttons-wrap {
                .ant-btn {
                  background-color: #A74970;
                  background-image: linear-gradient(to right, #b44171, #87498d);
                  border-radius: 20px;
                  color: #fff;
                  width: 100%;
                  min-height: 48px;
                  font-weight: 600;
                  font-size: 16px;

                  &:disabled {
                    pointer-events: all;
                    cursor: not-allowed;
                    background-color: #d1cfcf;
                    color: #f0f0f0;
                  }
                }
              }
            }

            .upload-info {
              // display: flex;
              align-items: center;
              justify-content: space-between;
              padding-bottom: 20px;
              color: #A74970;
              font-size: 12px;
              padding-top: 8px;
              font-weight: 700;
            }
          }
        }
      }
    }

    .why-curia-page {
      padding: 0 50px;

      &-title {
        font-size: 40px;
        line-height: 53px;
        text-align: center;
        font-weight: 600;
        padding-bottom: 40px;
        position: relative;

        &::after {
          content: '';
          width: 100px;
          height: 8px;
          background-color: #454343;
          display: flex;
          justify-content: center;
          position: absolute;
          left: 47%;
          bottom: 25px;
        }
      }

      &-layout {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        text-align: center;
        background: #54738e0f;
        mix-blend-mode: normal;
        border-radius: 6px;
        padding: 40px 50px;

        .easy-steps {
          width: 100%;
          padding-bottom: 40px;
          margin-bottom: 40px;
          position: relative;

          &::after {
            content: '';
            background: #0003;
            height: 1px;
            width: 92%;
            left: calc(100% - 96%);
            bottom: 0;
            position: absolute;
          }

          &:last-child {
            padding-bottom: 0;
            border-bottom: none;
            margin-bottom: 0;

            &::after {
              content: '';
              display: none;
            }
          }

          &-title-big {
            color: #a94476;
            font-size: 55px;
            font-weight: 600;
            padding-bottom: 12px;
          }

          &-title-small {
            color: #a94476;
            font-size: 24px;
            font-weight: 600;
            padding-bottom: 20px;
          }

          &-show {
            display: flex;
            padding-top: 40px;

            &-step {
              min-width: 33%;
              max-width: 33%;
              padding-right: 20px;
              text-align: center;

              .doc-info-text {
                font-style: italic;
                font-size: 11px;
                line-height: 18px;
                color: #a94476;
                font-weight: 600;
              }

              &-img {
                &.step-img-four {
                  background-image: url(./images/step-pay-documents.svg);
                  width: 138px;
                  height: 88px;
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  margin: 0 auto;
                }

                &.step-img-five {
                  background-image: url(./images/step-plane.svg);
                  width: 138px;
                  height: 88px;
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  margin: 0 auto;
                }

                &.step-img-six {
                  background-image: url(./images/step-hourglass.svg);
                  width: 138px;
                  height: 88px;
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  margin: 0 auto;
                }

                &.step-img-seven {
                  background-image: url(./images/step-process.svg);
                  width: 138px;
                  height: 88px;
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  margin: 0 auto;
                }

                &.step-img-eight {
                  background-image: url(./images/step-report.svg);
                  width: 138px;
                  height: 88px;
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  margin: 0 auto;
                }

                &.step-img-nine {
                  background-image: url(./images/step-callreq.svg);
                  width: 138px;
                  height: 88px;
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  margin: 0 auto;
                }
              }

              &-text {
                font-size: 16px;
                line-height: 21px;
                font-weight: 600;
                padding-top: 20px;
              }
            }
          }
        }

        .card-text-why-curia {
          padding: 20px;
          background-color: #EBEFF2;
          border-radius: 8px;
          line-height: 22px;

          &-text {
            padding-bottom: 20px;

            .quote-sec {
              background-image: url(./images/quote-img.svg);
              background-repeat: no-repeat;
              background-size: contain;
              min-width: 22px;
              min-height: 22px;
              display: inline-block;
              position: relative;
              bottom: -3px;
              right: 3px;
            }

            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }

    .testimonial-page {
      padding: 50px;

      .speakers-and-testimonials {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        &-y {
          min-width: 64%;
          max-width: 64%;
          padding-right: 30px;
          background: #F5F7F8;
          border-radius: 6px;

          &-content {
            min-width: 90%;
            max-width: 90%;
            padding: 50px;

            &-title {
              font-weight: 700;
              font-size: 30px;
              line-height: 38px;
              padding-bottom: 40px;
            }

            &-ytube {
              display: flex;
              justify-content: center;

              .react-player__play-icon {
                display: none;
              }
            }
          }
        }
      }

      &-title {
        font-size: 40px;
        line-height: 53px;
        text-align: center;
        font-weight: 600;
        padding-bottom: 40px;
        position: relative;

        &::after {
          content: '';
          width: 100px;
          height: 8px;
          background-color: #454343;
          display: flex;
          justify-content: center;
          position: absolute;
          left: 47%;
          bottom: 25px;
        }
      }

      .testimonial-page-content {
        background-color: #f5f7f8;
        padding: 40px 0;
        min-width: 33%;
        max-width: 33%;

        .ant-carousel {
          height: 100%;

          .slick-slider {
            height: 100%;
          }
        }

        .slick-dots {
          bottom: -10px;

          li {
            button {
              background: #585353;
            }
          }

          .slick-active {
            button {
              background: #a94376;
              opacity: 1;
            }
          }

        }

        .testimonial-page-content-in {
          background-color: #f5f7f8;

          // padding: 40px 140px;
          .testimonial-page-content-slide {
            padding: 0 30px;
          }
        }

        .testimonial-slide {
          background-color: #fff;
          padding: 40px;
          border-radius: 35px;
          font-size: 18px;
          line-height: 32px;
          font-weight: 500;
          min-height: 300px;

          &-content {
            &::before {
              background-image: url(./images/exla.svg);
              min-width: 38px;
              min-height: 27px;
              background-size: contain;
              background-repeat: no-repeat;
              content: '';
              display: inline-flex;
              margin-right: 10px;
            }
          }
        }

        .patient-name {
          text-align: right;
          font-weight: 600;
          font-size: 22px;
          line-height: 23px;

          &-content {
            display: inline-block;
            text-align: left;
          }
        }
      }
    }

    .more-info-curia-page {
      padding: 0 60px 50px 60px;

      &-content {
        text-align: center;
        font-weight: 700;

        .more-info-curia-page-footer-text {
          text-align: center;
          font-weight: 700;
          padding-top: 30px;
        }

        &-title {
          font-size: 22px;
          line-height: 29px;
          font-weight: 600;
          padding-bottom: 25px;
          text-align: center;
        }

        &-apps {
          display: flex;
          align-items: center;
          justify-content: center;

          &-img {
            padding: 0 20px;
          }

          .apple-link-img-icon {
            background-image: url(./images/apple-store-img.svg);
            min-width: 180px;
            min-height: 52px;
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-flex;
          }

          .android-link-img-icon {
            background-image: url(./images/google-play-img.svg);
            min-width: 180px;
            min-height: 52px;
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-flex;
          }
        }
      }
    }
  }

  .page-second-opinion-footer {
    padding: 40px 0;
    background-color: #a944760f;

    &-content {
      text-align: center;

      &-logo {
        background-image: url(./images/curia-logo.svg);
        width: 256px;
        height: 46px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin: 0 auto;
      }

      &-followus {
        padding-top: 25px;

        &-title {
          color: #9a467e;
          font-size: 24px;
          line-height: 32px;
          padding-bottom: 10px;
        }

        &-link {
          display: flex;
          justify-content: center;
          align-items: center;

          >a {
            padding: 0 12px;
          }

          .page-second-opinion-footer-content-followus {
            &.link-insta {
              background-image: url(./images/insta-icon.svg);
              min-width: 25px;
              min-height: 25px;
              background-size: contain;
              background-repeat: no-repeat;
              display: inline-flex;
              background-position: center;
            }

            &.link-fb {
              background-image: url(./images/fb-icon.svg);
              min-width: 25px;
              min-height: 25px;
              background-size: 24px;
              background-repeat: no-repeat;
              display: inline-flex;
              background-position: center;
            }

            &.link-discord {
              background-image: url(./images/discord-icon.svg);
              min-width: 35px;
              min-height: 27px;
              background-size: 31px;
              background-repeat: no-repeat;
              display: inline-flex;
              background-position: center;
            }
          }
        }
      }

      .footer-menu-sec {
        &-in {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 40px;

          &-links {
            padding-right: 50px;

            &:last-child {
              padding-right: 0;
            }

            a {
              color: #1f1f1f;
              font-weight: 600;
            }
          }
        }
      }

      .footer-text {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        font-size: 14px;
        line-height: 24px;
        color: #878787;

        sup {
          font-size: 10px;
        }

        a {
          padding: 0 10px;
          margin: 0 10px;
          color: #878787;
        }
      }
    }
  }
}

@media (max-width:767px) {
  .page-second-opinion {
    overflow-y: hidden;

    .for-desktop {
      display: none;
    }

    .for-mobile {
      display: flex;
      text-align: center;
      padding-bottom: 20px;
      font-size: 24px;
      font-weight: 700;
      padding-top: 10px;
      justify-content: center;
      width: 100%;
    }

    .slider-show {
      .ant-carousel {
        .slick-track {
          max-height: 460px;
          overflow: hidden;
        }
      }
    }

    .page-second-opinion-container {

      .listen-ytube-page {
        padding-top: 18px;

        &-title {
          font-size: 22px;
          line-height: 28px;

          .text-small {
            font-size: 22px;
          }
        }

        .book-btn {
          .btn-book-now {
            position: fixed;
            right: 10px;
            bottom: 18%;
            border: 1px solid #fff;
            z-index: 100;
          }
        }

        .listen-ytube-page-emb-code {
          padding: 15px 10px 0 10px;

          >div {
            min-width: 100%;
            max-width: 100%;

            .react-player__preview {
              background-size: contain !important;
              background-repeat: no-repeat;
            }
          }

          .video-responsive {
            iframe {
              min-width: 100%;
              max-width: 100%;
              height: 100%;
              min-height: 283px;
            }
          }
        }
      }

      .slider-show {
        .ant-carousel {
          .slick-dots-bottom {
            bottom: 0;
          }
        }

        .carousel-content {
          position: absolute;
          z-index: 99;
          bottom: calc(100% - 90%);
          width: 100%;
          text-align: center;

          .slide-buttons {
            .btn-book-now {
              display: none;
            }
          }

          .slide-sub-text {
            font-size: 14px;
            color: #fff;
            padding-top: 5px;
            line-height: 20px;
          }

          .slide-sub-text-top {
            font-size: 14px;
            color: #fff;
            padding-top: 10px;
            line-height: 20px;
            padding-bottom: 5px;
          }

          .slide-main-text {
            font-size: 20px;
            font-weight: 500;
          }
        }

        .carousel-slide {
          .slide-img {
            width: 100%;
            background-position: 52%;
            background-repeat: no-repeat;
            background-size: cover;
            max-height: 500px;

            &::after {
              content: "";
              height: 880px
            }
          }
        }
      }

      .how-does-it-work-page {
        padding: 0 10px 10px 10px;

        &-title {
          display: none;

          &::after {
            content: "";
            left: calc(100% - 63%);
          }
        }
      }

      .how-does-it-work-page-layout {
        flex-wrap: wrap;
        flex-direction: column-reverse;

        &-left {
          max-width: 100%;
          min-width: 100%;
          padding: 12px 0 0 0;
          border: none;
          display: block;

          .easy-steps {
            border-radius: 6px;
            padding: 0px 45px 20px 45px;

            .for-mobile {
              display: flex;
              text-align: center;
              padding-bottom: 20px;
              font-size: 24px;
              font-weight: 700;
              padding-top: 10px;
              justify-content: center;
            }

            .easy-steps-title-big {
              text-align: center;
              font-size: 24px;
              line-height: 30px;
              padding-bottom: 0;
            }

            .easy-steps-title-small {
              color: #a94476;
              font-size: 23px;
              font-weight: 600;
              text-align: center;
            }
          }

          .easy-steps-show {
            flex-wrap: wrap;
            padding-right: 0;
          }

          .easy-steps-show-step {
            min-width: 100%;
            max-width: 100%;
            padding-bottom: 25px;
            border-bottom: 1px solid #d5d5d5;
            margin-bottom: 25px;
            padding-right: 10px;

            &:last-child {
              border: none;
              padding-bottom: 0;
            }
          }
        }

        &-right {
          max-width: 100%;
          min-width: 100%;
          padding: 0;
          margin-bottom: 15px;
          position: relative;
          z-index: 9999;

          .thankyou-page {
            position: relative;
          }

          .upload-form-sec {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .title-sec {
              .form-sec-title {
                padding: 15px;
              }
            }

            .form-wrap {
              .form-input-itm {
                padding-bottom: 10px;
                padding-left: 10px;

                &.mobilenumber {
                  flex-wrap: nowrap;

                  input {
                    &.mobile-no {
                      margin-right: 10px;
                    }
                  }
                }

                &.question {
                  textarea {
                    width: calc(100% - 10px);
                  }
                }

                input {
                  width: calc(100% - 26px);
                }
              }

              .fields-info-text {
                padding-left: 12px;
              }
            }

            .upload-wrap {
              padding: 0 18px 20px 18px;

              .consent-wrap {
                font-weight: 700;
                color: #525151;
                padding-bottom: 15px;
                font-size: 12px;
              }
            }
          }
        }
      }

      .testimonial-page {
        padding: 0 10px;

        .speakers-and-testimonials {
          flex-wrap: wrap;
          width: 100%;

          .speakers-and-testimonials-y {
            min-width: 100%;
            max-width: 100%;
            padding-right: 0;
            padding-top: 15px;
            border-radius: 6px;

            .speakers-and-testimonials-y-content {
              padding: 25px 0;
              margin: 0 auto;
              min-width: 100%;
              max-width: 100%;

              &-title {
                padding-left: 10px;
                padding-right: 10px;
                font-size: 15px;
                line-height: 22px;
                padding-bottom: 10px;
              }

              .speakers-and-testimonials-y-content-ytube {
                min-width: 94%;
                max-width: 94%;
                padding: 0;
                margin: 0 auto;

                >div {
                  min-width: 100%;
                  max-width: 100%;

                  .react-player__preview {
                    background-size: contain !important;
                    background-repeat: no-repeat;
                  }
                }
              }
            }
          }
        }

        &-title {
          &::after {
            content: "";
            left: calc(100% - 63%);
          }
        }

        .testimonial-page-content {
          padding: 10px 0 40px 0;
          min-width: 100%;
          max-width: 100%;

          .slick-slide {
            max-width: 100%;
          }

          .testimonial-page-content-in {
            max-width: 80%;
            margin: 0 auto;
            padding-left: 15px;

            .testimonial-page-content-slide {
              padding: 0;
              margin-left: 10px;
            }
          }

          .testimonial-slide {
            min-width: 100%;
            max-width: 100%;
            padding: 25px 20px;
            font-size: 17px;
            line-height: inherit;
          }


          .slick-dots {
            bottom: -30px;

            li {
              button {
                background: #585353;
              }
            }

            .slick-active {
              button {
                background: #a94376;
                opacity: 1;
              }
            }

          }
        }
      }

      .why-curia-page {
        padding: 8px 0 20px 0;

        &-title {
          display: none;

          &::after {
            content: "";
            left: calc(100% - 63%);
          }
        }

        .why-curia-page-main {
          padding: 0 10px;

          .why-curia-page-layout {
            padding: 15px 25px;

            .easy-steps {
              &:first-child {
                padding-bottom: 0;
              }

              .easy-steps-show {
                flex-wrap: wrap;

                &-step {
                  min-width: 100%;
                  max-width: 100%;
                  padding-bottom: 25px;
                  border-bottom: 1px solid #d5d5d5;
                  margin-bottom: 20px;
                  padding-right: 10px;

                  &:last-child {
                    border: none;
                  }
                }
              }
            }
          }
        }
      }

      .more-info-curia-page {
        padding: 25px 20px 30px 30px;

        .more-info-curia-page-footer-text {
          font-size: 12px;
        }

        .more-info-curia-page-content-apps {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          &-img {
            padding: 0 20px 10px 20px;
          }
        }
      }
    }

    .page-second-opinion-header {
      padding: 0 10px;
      min-height: 45px;

      .logo-curia-img {
        background-image: url(./images/curia-icon.svg);
        width: 42px;
      }

      .header-text-in {
        font-weight: 600;
        font-size: 15px;
      }

      .header-buttons {
        .header-button-btn {
          .btn-book-now {
            display: none;
          }
        }
      }
    }

    .page-second-opinion-footer {
      padding: 40px 0 10px 0;

      &-content-logo {
        width: 114px;
        height: 25px;
      }
    }


    .page-second-opinion-footer-content {
      .footer-menu-sec-in {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
        flex-wrap: wrap;

        &-links {
          padding-right: 0;
          width: 100%;
          padding-bottom: 10px;
        }
      }

      .footer-text {
        flex-wrap: wrap;

        .footer-text-copyright {
          width: 100%;
        }
      }

    }
  }
}

@media (max-width: 500px) {
  .page-second-opinion-container {
    .slider-show {
      .carousel-slide {
        position: relative;


        &.slide-one {
          .slide-img {
            background-image: url(./images/mobile-banner-one.png);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.slide-two {
          .slide-img {
            background-image: url(./images/mobile-banner-two.png);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        &.slide-three {
          .slide-img {
            background-image: url(./images/mobile-banner-three.png);
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        .slide-img {
          width: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          max-height: 500px;
          bottom: 40px;

          &::after {
            content: "";
            height: 880px
          }
        }
      }
    }

    .listen-ytube-page-emb-code {
      padding: 15px 10px 0 10px;

      >div {
        min-width: 100%;
        max-width: 100%;
        max-height: 210px !important;
        min-height: 210px !important;

        .react-player__preview {
          background-size: contain !important;
          background-repeat: no-repeat;
        }
      }
    }

    .speakers-and-testimonials-y-content-ytube {
      padding: 15px 10px 0 10px;

      >div {
        min-width: 100%;
        max-width: 100%;
        max-height: 210px !important;
        min-height: 210px !important;

        .react-player__preview {
          background-size: contain !important;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.error-message {
  font-size: 11px;
  color: #ff0000;
  padding-top: 5px;
  text-align: left;
  font-weight: 600;
  padding-left: 4px;
}

.ant-modal {
  &.otp-sec-modal {

    .ant-modal-content {
      padding: 0;
      background-color: #fffcfc;
    }

    .ant-modal-header {
      color: #898989e0;
      background: #f5dde9;
      border-radius: 8px 8px 0 0;
      margin-bottom: 8px;
      padding: 15px 20px;

      .ant-modal-title {
        font-weight: 700;
        font-size: 19px;
      }
    }
  }
}

.verify-otp-wrap {
  padding: 15px 20px;

  .verify-otp-text {
    font-weight: 600;
    padding-bottom: 5px;
    color: #000000;
    font-size: 16px;
  }

  .verify-otp-input {
    input {
      padding: 10px 11px;
      margin-bottom: 10px;
      border: 1px solid #8e8d8d;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .submit-wrap {
    .ant-btn {
      background: #a94476;
      background-image: linear-gradient(to right, #b44171, #87498d);
      border-radius: 8px;
      height: 40px;
      color: #fff;
      font-weight: 700;
      font-size: 14px;
    }
  }
}